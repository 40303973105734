import { useMemo, useState } from "react";
import { MachineTypeViewModel, ModelVariantViewModel } from "../types";
import { AccordionModel } from "../types";
import { debounce } from "ui";

const translations = window.app.preloadState.translation;

export function MachineTypes({ model }: { model: AccordionModel }) {
	const [query, setQuery] = useState<string>("");

	const machineTypes = useMemo(() => {
		const lowerCaseQuery = query.toLowerCase();
		return model.machineTypes
			.map(type => ({
				...type,
				modelVariants: type.modelVariants.filter(variant => variant.label.toLowerCase().includes(lowerCaseQuery))
			}))
			.filter(type => type.modelVariants.length > 0)
	}, [query]);

	const debouncedInput = useMemo(() => debounce(setQuery, 500), []);

	return (<div className="machineTypes">
		<div className="articleInputSearch">
			<input type="text" placeholder={model.modelViewTabFilterPlaceholder} onInput={ev => debouncedInput(ev.currentTarget.value ?? "")} />
		</div>
		{machineTypes.length > 0 ? 
			machineTypes.map(tab => <MatchingTypeModelsView
				key={tab.label}
				{...tab}
			/>
			)
			:
			<div>{model.noSparePartsFitsMyMachineNote}</div>
		}
	</div>);
}

function MatchingTypeModelsView({ label, modelVariants }: MachineTypeViewModel) {
	const pageSize = 12;

	const [count, setCount] = useState(pageSize);
	return <div className="details">
		<input type="checkbox" name={label} id={label} />
		<label htmlFor={label}>{label}</label>
		<div className="content">
			<div className="accordionLinks">
				{modelVariants.slice(0, count).map(modelVariant => <AccordionMachine key={modelVariant.code} {...modelVariant} />)}
			</div>
			{(modelVariants.length > count) && <div className="showMoreButton" role="button" onClick={() => setCount(c => c + pageSize)}>{translations["sparePartVariationButtonOptions.showMore"]}</div>}
		</div>
	</div>
}

function AccordionMachine(modelVariant: ModelVariantViewModel) {
	const [isOpen, setOpen] = useState(false);

	if (modelVariant.catalogs.length === 0) {
		return <a className="accordionLink" href={modelVariant.url}>{modelVariant.label}</a>;
	} else {
		return <div className={"accordionLinkList " + (isOpen ? "accordionLinkList--open" : "")}>
			<span className="accordionLink accordionLinkIcon cursor-pointer" onClick={() => setOpen(!isOpen)}>{modelVariant.label}</span>
			<div>
				{modelVariant.catalogs.map(catalog => <span key={catalog.code} className="accordionLink">{catalog.label}</span>)}
			</div>
		</div>;
	}
}