import { MachineSelectorButton, debounce } from "ui";
import { AccordionModel, ExplodedViewItemModel } from "../types";
import { SelectedVehicle } from "ui/src/types";
import { useLayoutEffect, useMemo, useState } from "react";

const translations = window.app.preloadState.translation;

export function ExplodedViews({ model, vehicle }: { model: AccordionModel, vehicle: SelectedVehicle | null }) {
	if (vehicle == null) {
		return <ExplodedViewsNoMachine model={model} vehicle={vehicle} />
	} else {
		return <ExplodedViewsPaginatedList model={model} vehicle={vehicle} />
	}
}

function ExplodedViewsPaginatedList({ model, vehicle }: { model: AccordionModel, vehicle: SelectedVehicle | null }) {
	const [page, setPage] = useState<number>(1);
	const [query, setQuery] = useState<string>("");
	const [items, setItems] = useState<ExplodedViewItemModel[]>([]);
	const [total, setTotal] = useState<number>(0);

	const debouncedInput = useMemo(() => debounce((str: string) => {
		setQuery(str);
		setPage(1);
	}, 500), []);

	useLayoutEffect(() => {
		(async () => {
			const params = new URLSearchParams({
				pageNumber: ""+page,
				query
			});
			const response = await fetch(`/api/pdp/${model.code}/exploded-views?${params.toString()}`,
				{
					headers: {
						"Swecon-Current-Language": window.app.preloadState.currentLanguage
					}
				});
			if (response.ok) {
				const { items: newItems, total }:{ items: ExplodedViewItemModel[], total: number } = await response.json();
				if (page === 1) {
					setItems(newItems);
				} else {
					setItems(items => [...items, ...newItems]);
				}
				setTotal(total);
			} else {
				console.error(await response.text());
			}
		})();
	}, [page, query]);

	const loadMore = () => setPage(i => i + 1);

	return <div className="explodedViews">
		<div className="explodedViewsHeader">
			<div className="articleInputSearch">
				<input type="text" placeholder={model.explodedViewTabFilterPlaceholder} onInput={ev => debouncedInput(ev.currentTarget.value ?? "")} />
			</div>
			<MachineSelectorButton vehicle={vehicle} shouldRedirectBack={true} />
		</div>
		<div className="explodedViewsBody">
			{items.length > 0 ?
				<>
					<div className="explodedViewCollection">{items.map(item => (
						<ExplodedViewItem item={item} key={item.contentLink} code={model.code} />))}
					</div>
					{(total !== items.length) && < div className="showMoreButton" role="button" onClick={loadMore}>{translations["sparePartVariationButtonOptions.showMore"]}</div>}
				</>
				:
				<div>{model.noExplodedViewInPdp}</div>
			}
			</div>
		</div>;
}

function ExplodedViewsNoMachine({ model, vehicle }: { model: AccordionModel, vehicle: SelectedVehicle | null }) {
	return <div className="explodedViewsNoMachine">
		<span>{model.noMachineSelectedExplodedViewTabInfo}</span>
		<MachineSelectorButton vehicle={vehicle} shouldRedirectBack={true} />
	</div>;
};

function ExplodedViewItem({ item, code }: { item: ExplodedViewItemModel, code: string }) {
	return <a key={item.contentLink} title={item.contentName} href={`${item.contentLink}?articleNumber=${code}`} className="sparePartsAccordion justify-content-space-between">
		{item.imageUrl &&
			<div className="sparePartsAccordionImage">
				<img src={item.imageUrl + "?height=260&rsampler=bicubic&format=jpg"} alt={item.contentName} />
			</div>
		}
		<div className="sparePartsAccordionTitle explodedViewSparePartsAccordionTitle">
			<span>{item.contentName}</span>
		</div>
	</a>
}