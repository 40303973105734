import { AccordionModel } from "../types";
import { useState } from "react";


export function TechnicalSpecificationView({ model }: { model: AccordionModel }) {
	return <div className="technicalSpecification">
		<hr />
		<div className="line">
			<span className="d-inline-block firstLetterCapital">{model.quantityFrpPdpLabel}</span>
			<span>{model.packageQuantity}</span>
		</div>
		{!!model.variationUnitId && <>
			<hr />
			<div className="line">
				<span className="d-inline-block firstLetterCapital">{model.unitPdpLabel}</span>
				<span>{model.variationUnitId}</span>
			</div>
		</>}
		{model.variationWeight != null && model.variationWeight > 0 && (<><hr />
			<div className="line">
				<span>{model.weightPdpLabel}</span>
				<span>{model.variationWeight} kg</span>
			</div></>)
		}
		{model.variationWidth != null && model.variationWidth > 0 && (<><hr />
			<div className="line">
				<span>{model.widthPdpLabel}</span>
				<span>{model.variationWidth} mm</span>
			</div></>)
		}
		{model.variationHeight != null && model.variationHeight > 0 && (<><hr />
			<div className="line">
				<span>{model.heightPdpLabel}</span>
				<span>{model.variationHeight} mm</span>
			</div></>)
		}
		{model.variationDepth != null && model.variationDepth > 0 && (<><hr />
			<div className="line">
				<span>{model.depthPdpLabel}</span>
				<span>{model.variationDepth} mm</span>
			</div></>)
		}
		{model.variationDensity != null && model.variationDensity > 0 && (<><hr />
			<div className="line">
				<span>{model.densityPdpLabel}</span>
				<span>{model.variationDensity}</span>
			</div></>)
		}
		{model.longDescription != null && (<><hr />
			<div className="line">
				<span>{model.descriptionLabel}</span></div>
			<div className="line">
				<span>{model.longDescription}</span>
			</div></>)
		}
		<hr />
	</div>
}