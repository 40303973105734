import "./app.scss";
import { useEffect, useState } from "react";

import { ExplodedViews } from "./components/ExplodedView";
import { TechnicalSpecificationView } from "./components/TechnicalSpecificationView";
import { MachineTypes } from "./components/MatchingModelsView";
import { ButtonGroup } from "ui";

const urlParams = new URLSearchParams(location.search);
const openMachineTab = urlParams.get('explodedView') === "true";

const model = window.app.preloadState.pdpAccordion;
const vehicle = window.app.preloadState.selectedVehicle;
const isLoggedIn = window.app.preloadState.isLoggedIn;

const explodedViewTabVisible = (!!model.explodedViewLabel) && isLoggedIn;

const tabs = [
	{ label: model.technicalSpecificationLabel, value: 1 },
	{ label: model.explodedViewLabel, value: 2, isVisible: explodedViewTabVisible },
	{ label: model.suitsToLabel, value: 3, isVisible: !!model.suitsToLabel }
];

function App() {
	const [visibleTabId, setVisibleTabId] = useState(openMachineTab && explodedViewTabVisible ? 2 : 1);

	useEffect(() => {
		if (openMachineTab && explodedViewTabVisible) {
			document.getElementById("pdp-accordion-title")?.scrollIntoView({ behavior: 'smooth' });
		}
	}, []);

	return <div className="tab">
		<div className="tabHead" id="pdp-accordion-title">
			<ButtonGroup theme="Dark" initialSelected={visibleTabId} onChecked={setVisibleTabId} items={tabs} />
		</div>
		<div className="tabContent">
			{visibleTabId == 1 && <TechnicalSpecificationView model={model} />}
			{visibleTabId == 2 && <ExplodedViews model={model} vehicle={vehicle} />}
			{visibleTabId == 3 && <MachineTypes model={model} />}
		</div>
	</div >
}

export default App;
